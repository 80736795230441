import React from "react";
import { graphql, PageProps } from "gatsby";

import BlogHero from "@components/blog/blogHero";
import { BlocksContent } from "@components/global";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import ColumnContent from "@components/global/columnContent";
import { Query } from "@graphql-types";
import { Container } from "@util/standard";
import {
  isSanityCategoryPanelPreview,
  isSanityColumn,
  isSanityColumnContent,
  isSanityRecipePreview,
  isSanitySuggestBlogs,
} from "@util/types";
import RelatedBlogs from "@components/blog/relatedBlogs";
import SEO from "@shared/seo";
import Layout from "@shared/layout";
import RecipePreview from "@components/global/recipePreview";

interface Props extends PageProps {
  data: Query;
}

function LearnInnerTemplate({ data }: Props) {
  const blog = data.allSanityBlog.nodes[0];

  return (
    <>
      {blog.seo && <SEO seoData={blog.seo} slug={`/learn/${blog.slug.current}`} />}
      <Layout>
        <BlogHero blogId={blog._id as string} data={blog} />
        {blog.pageContent &&
          blog.pageContent.map(data => {
            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} />;
            }

            if (isSanityCategoryPanelPreview(data)) {
              return <CategoryPanelPreview data={data} key={data._key} />;
            }

            if (isSanityColumn(data)) {
              return (
                <Container width="65%" tabletWidth="80%" margin="100px auto" key={data._key}>
                  <BlocksContent blocks={data._rawColumnContent} />
                </Container>
              );
            }

            if (isSanitySuggestBlogs(data)) {
              return <RelatedBlogs data={data} key={data._key} />;
            }

            if (isSanityRecipePreview(data)) {
              return <RecipePreview data={data} key={data._key} />;
            }
            return;
          })}
      </Layout>
    </>
  );
}

export const query = graphql`
  query allBlogsQuery($slug: String) {
    allSanityBlog(filter: { slug: { current: { eq: $slug } } }) {
      nodes {
        ...sanityBlog
      }
    }
  }
`;

export default LearnInnerTemplate;
